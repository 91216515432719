import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import formatAddress from '../../../lib/formatAddress';
import formatDate from '../../../lib/format';
import { formatHourToRange } from '../../../lib/helpers/availability';
import { stringifyPartyMembers, groupGuests } from '../../../lib/helpers/party';

import BookingSummaryItem from '../../BookingSummary/BookingSummaryItem';
import Title from '../../ui/Title';

import {
  Address,
  Campsite,
  Header,
} from '../BasketItem.style';

import { DATE_FORMAT_DISPLAY } from '../../../config/locale';
import IbePropTypes from '../../../IbePropTypes';
import { differenceInDays } from '../../../lib/dates';
import EXTRA_TYPES from '../../../config/extraTypes';

function BasketItemCampsite(props) {
  const pitchItem = props.pitches.find(pitch => pitch.code === props.code);
  const arrivalHour = moment(pitchItem?.arrivalDateTime).hours();
  const arrivalTime = ` (${formatHourToRange(arrivalHour)})`;
  const arriveFromDate = formatDate(props.dates.fromDate, DATE_FORMAT_DISPLAY);
  const arriveText = `${arriveFromDate}${pitchItem?.arrivalDateTime ? arrivalTime : ''}`;
  const numNights = differenceInDays(props.dates.toDate, props.dates.fromDate);

  const party = props.partyMemberReference?.map((referenceId) => (
    props.partyMembers?.find(partyMember => partyMember.personId === referenceId)
  ))?.filter((partyMember => !!partyMember));
  const partyString = stringifyPartyMembers(
    party,
    props.partyMemberTypes,
    null,
    false,
    {
      withPlurals: true,
    },
  );
  // Main party member prices are stored as Occupancy supplements on the quote
  const occupancySupplements = pitchItem.supplements.filter(
    (supplement) => supplement.type === EXTRA_TYPES.OCCUPANCY,
  );
  const partyTotalPrice = occupancySupplements.reduce(
    (total, supplement) => total + supplement.totalPrice, 0,
  );

  const title = props.parentPitchId ? `Guest Party ${props.index}` :
    props.campsite.name;
  const description = props.parentPitchId ? partyString :
    formatAddress(props.campsite.address);

  const renderGuests = (showPrice = true) => {
    const groupedGuests = groupGuests(props.guests);
    const allGuests = Object.values(groupedGuests) ?? [];
    const calculateGuestGroupPrice = (guests) => guests.reduce((total, guest) => (
      total + guest.guestAmount
    ), 0);
    return allGuests.map((guestItems, i) => (
      <Fragment>
        <BookingSummaryItem
          isResponsive={props.bigBasket}
          value={{
            name: `Part stay guests: ${stringifyPartyMembers(guestItems, props.partyMemberTypes, null, false, {
              withPlurals: true,
            })}`,
            price: showPrice ? calculateGuestGroupPrice(guestItems) : null,
          }}
        />
        <BookingSummaryItem
          isResponsive={props.bigBasket}
          label=""
          value={{
            name: '',
            price: null,
            description: `${formatDate(
              guestItems[0]?.stayStart, DATE_FORMAT_DISPLAY,
            )} - ${formatDate(
              guestItems[0]?.stayEnd, DATE_FORMAT_DISPLAY,
            )}`,
          }}
          noMargin
        />
      </Fragment>
    ));
  };

  const renderSupplement = (supplement) => {
    const name = supplement.name || supplement.description;
    const suffix = (supplement.isMandatory || !supplement.quantity) ? '' : `x ${supplement.quantity}`;
    return {
      name: supplement.quantity ? `${name} ${suffix}` : '',
      price: props.isItx || props.reviewItem ? null : supplement.totalPrice,
    };
  };

  const commonItemProps = {
    isResponsive: props.bigBasket,
    reviewItem: props.reviewItem,
  };

  const supplementsToRender = pitchItem.supplements.filter(
    (supplement) => supplement.type !== EXTRA_TYPES.OCCUPANCY,
  );

  const showPrices = !props.isBookingBasket;

  return (
    <Fragment>
      {!props.bigBasket && (
        <Header>
          <Campsite full={props.bigBasket}>
            <Title tag={3} size={4}>{title}</Title>
            <Address>{description}</Address>
          </Campsite>
        </Header>
      )}
      <BookingSummaryItem label="Arrive" value={{ name: arriveText, price: null }} {...commonItemProps} />
      <BookingSummaryItem label="Depart" value={{ name: formatDate(props.dates.toDate, DATE_FORMAT_DISPLAY), price: null }} {...commonItemProps} />
      {(props.bigBasket || props.isBookingBasket) &&
        <Fragment>
          <BookingSummaryItem
            label="Duration"
            value={{ name: `${numNights} ${numNights > 1 ? 'nights' : 'night'}`, price: null }}
            {...commonItemProps}
          />
          {partyString && (
            <BookingSummaryItem label="Party members" value={{ name: partyString, price: showPrices && partyTotalPrice }} {...commonItemProps} />
          )}
          <BookingSummaryItem
            label="Pitch"
            value={{
              name: props.type,
              price: props.isItx || props.reviewItem || !showPrices
                ? null
                : props.basePrice,
            }}
            {...commonItemProps}
          />
          {props.outfit && props.reviewItem && (
            <BookingSummaryItem label="Outfit" value={{ name: props.outfit, price: null }} {...commonItemProps} />
          )}
          <BookingSummaryItem
            label="Special requests"
            value={[...props.specialRequests].map(request => ({
              name: `${request.name}`,
            }))}
            {...commonItemProps}
          />
          <BookingSummaryItem
            label="Extras"
            value={supplementsToRender.map(renderSupplement)}
            duration={numNights}
            {...commonItemProps}
          />
          {renderGuests(showPrices)}
        </Fragment>
      }
    </Fragment>
  );
}

BasketItemCampsite.propTypes = {
  isItx: PropTypes.bool.isRequired,
  basePrice: PropTypes.number.isRequired,
  bigBasket: PropTypes.bool.isRequired,
  campsite: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.shape({
      addressLines: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  }).isRequired,
  isBookingBasket: PropTypes.bool,
  supplements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dates: PropTypes.shape({
    toDate: PropTypes.string,
    fromDate: PropTypes.string,
  }).isRequired,
  code: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  pitches: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.pitch)).isRequired,
  partyMemberTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  guests: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.guest)),
  partyMembers: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.partyMember)),
  parentPitchId: PropTypes.number,
  index: PropTypes.number,
  partyMemberReference: PropTypes.arrayOf(PropTypes.number),
  specialRequests: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  outfit: PropTypes.shape(IbePropTypes.outfit),
  reviewItem: PropTypes.bool,
};

BasketItemCampsite.defaultProps = {
  guests: [],
  isBookingBasket: false,
  partyMembers: [],
  parentPitchId: 0,
  index: 1,
  partyMemberReference: [],
  specialRequests: [],
  outfit: {},
  reviewItem: false,
};

export default memo(BasketItemCampsite);
